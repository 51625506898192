import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ROUTES, { compareRoute, matchRoute } from '@app/routes';
import ClientMenu from '@components/Layout/ClientMenu';
import useSearch from '@hooks/useSearch';

import GET_SOLUTIONS from './graphql/getSolutions.graphql';

const ClientMenuContainer = ({
  open = true,
  pathname = '',
  queryParams = '',
  company: { id: companyId, qrcodeOption, dataOption, followUpOption, externalVideoOption },
}) => {
  const { t } = useTranslation();
  const MATCH_ID_REGEX = '[0-9-a-f]+';
  const MATCH_CRYPT_ID_REGEX = '[0-9A-Za-z=]+';
  const MATCH_PLATEFORM_REGEX = '[a-zA-Z_]+';

  const { followup, menu } = useSearch();
  const [qrcodeDropdownMenu, setQrcodeDropdownMenu] = useState(false);
  const [solutionDropdownMenu, setSolutionDropdownMenu] = useState(null);
  const [allVideosDropdownMenu, setAllVideosDropdownMenu] = useState(null);
  const [followupDropdownMenu, setFollowupDropdownMenu] = useState(null);

  const { data: getSolutionsData, loading: getSolutionsLoading } = useQuery(GET_SOLUTIONS, { variables: { companyId } });

  const solutions =
    getSolutionsData?.getCompany?.solutions?.filter(
      (s) =>
        ['PLATFORM_BOOK', 'PLATFORM_PRODUCT', 'PLATFORM_BASIC', 'PLATFORM_CHALLENGE'].includes(s.type) &&
        (!!s.orderClientModerationEnabled || !!s.productClientModerationEnabled || !!s.applicationClientModerationEnabled || !!s.videoClientModerationEnabled)
    ) || [];

  const smartUploadersToShow = getSolutionsData?.getCompany?.solutions?.filter((s) => s.type === 'SMART_UPLOADER' && s.config?.clientModeration && !s.config?.platformLinkedId) || [];

  const getGeneralMenuItem = () => ({
    title: t('Home'),
    icon: 'GENERAL',
    onClick: ROUTES.DASHBOARD.path({ companyId }),
    isSelected:
      matchRoute(new RegExp(`^${ROUTES.DASHBOARD.path({ companyId: MATCH_ID_REGEX })}$`), pathname) ||
      (matchRoute(
        new RegExp(
          `^${ROUTES.PROJECT.path({ companyId: MATCH_ID_REGEX, projectId: MATCH_ID_REGEX, solutionId: MATCH_ID_REGEX, solutionType: MATCH_PLATEFORM_REGEX })}$`
        ),
        pathname
      ) &&
        queryParams.includes('previous=DASHBOARD')),
  });

  const getSubMenuItems = (
    {
      id: solutionId,
      type: solutionType,
      applicationClientModerationEnabled,
      orderClientModerationEnabled,
      videoClientModerationEnabled,
      productClientModerationEnabled,
      linkedCasting: castingId,
    },
    overviewRouteMatch,
    followupRouteMatch,
    applicationsRouteMatch,
    ordersRouteMatch,
    videosRouteMatch,
    productsRouteMatch
  ) => {
    const items = [];

    const showFollowUpItem = followUpOption && (applicationClientModerationEnabled || orderClientModerationEnabled || videoClientModerationEnabled);

    if (followUpOption) {
      items.push({
        key: `PLATFORM-${solutionId}-OVERVIEW`,
        name: t('Campaign overview'),
        selected: overviewRouteMatch,
        onClick: ROUTES.CAMPAIGN_OVERVIEW.path({ companyId, solutionId }),
      });
    }

    if (showFollowUpItem) {
      items.push({
        key: `PLATFORM-${solutionId}-FOLLOWUP`,
        name: t('Followup'),
        selected: followupRouteMatch,
        open: followupDropdownMenu === `PLATFORM_BOOK-FOLLOWUP-${solutionId}` || followupRouteMatch,
        onClick: () => setFollowupDropdownMenu(followupDropdownMenu === `PLATFORM_BOOK-FOLLOWUP-${solutionId}` ? null : `PLATFORM_BOOK-FOLLOWUP-${solutionId}`),
        children: [
          applicationClientModerationEnabled && {
            key: `PLATFORM_BOOK-APPLICATIONS-${solutionId}`,
            name: t('Applications'),
            selected: applicationsRouteMatch,
            onClick: ROUTES.FOLLOWUP.path(
              { companyId, solutionId },
              {
                menu: 'PLATFORM',
                followup: solutionId,
                tab: 'APPLICATIONS',
              }
            ),
          },
          orderClientModerationEnabled && {
            key: `PLATFORM_BOOK-ORDERS-${solutionId}`,
            name: t('Orders'),
            selected: ordersRouteMatch,
            onClick: ROUTES.FOLLOWUP.path(
              { companyId, solutionId },
              {
                menu: 'PLATFORM',
                followup: solutionId,
                tab: 'ORDERS',
              }
            ),
          },
          videoClientModerationEnabled && {
            key: `PLATFORM_BOOK-VIDEOS-${solutionId}`,
            name: t('Videos'),
            selected: videosRouteMatch,
            onClick: ROUTES.FOLLOWUP.path(
              { companyId, solutionId },
              {
                menu: 'PLATFORM',
                followup: solutionId,
                tab: 'VIDEOS',
              }
            ),
          },
        ].filter((i) => !!i),
      });
    }

    if (!followUpOption && orderClientModerationEnabled) {
      items.push({
        key: `PLATFORM_BOOK-ORDERS-${solutionId}`,
        name: t('Orders'),
        selected:
          matchRoute(new RegExp(`^${ROUTES.ORDER_LIST.path({ companyId: MATCH_ID_REGEX, solutionId: solutionId })}$`), pathname) ||
          matchRoute(new RegExp(`^${ROUTES.ORDER.path({ companyId: MATCH_ID_REGEX, orderId: MATCH_CRYPT_ID_REGEX, solutionId: solutionId })}$`), pathname),
        onClick: ROUTES.ORDER_LIST.path({ companyId, solutionId }),
      });
    }

    if (!followUpOption && videoClientModerationEnabled) {
      items.push({
        key: `PLATFORM_BOOK-VIDEOS-${solutionId}`,
        name: t('Videos'),
        selected:
          matchRoute(
            new RegExp(`^${ROUTES.PROJECT_LIST.path({ companyId: MATCH_ID_REGEX, solutionId: solutionId, solutionType: MATCH_PLATEFORM_REGEX })}$`),
            pathname
          ) ||
          (matchRoute(
            new RegExp(
              `^${ROUTES.PROJECT.path({ companyId: MATCH_ID_REGEX, projectId: MATCH_ID_REGEX, solutionId: solutionId, solutionType: MATCH_PLATEFORM_REGEX })}$`
            ),
            pathname
          ) &&
            !queryParams.includes('previous')),
        onClick: ROUTES.PROJECT_LIST.path({ companyId, solutionId, solutionType: 'platform' }, { previous: 'LIST' }),
      });
    }

    if (!followUpOption && applicationClientModerationEnabled) {
      items.push({
        key: `PLATFORM_BOOK-APPLICATIONS-${solutionId}`,
        name: t('Applications'),
        selected: compareRoute(ROUTES.PLATFORM_APPLICATION_LIST.path({ companyId, solutionId: castingId }), pathname),
        onClick: ROUTES.PLATFORM_APPLICATION_LIST.path({ companyId, solutionId: castingId }),
      });
    }

    if (productClientModerationEnabled) {
      items.push({
        key: `PLATFORM_BOOK-PRODUCTS-${solutionId}`,
        name: t('Products'),
        selected: productsRouteMatch,
        onClick: ROUTES.PRODUCT_LIST.path({ companyId, solutionId }),
      });
    }

    return items;
  };

  const getSmartUploaderItems = () => {
    return smartUploadersToShow.map((s) => ({
      key: `SMART_UPLOADER-${s.id}`,
      title: s.name,
      icon: 'ROCKET_LAUNCH_SOLID',
      selected: matchRoute(new RegExp(`^${ROUTES.PROJECT_LIST.path({ companyId: MATCH_ID_REGEX, solutionId: s.id, solutionType: 'smart-uploader' })}$`), pathname),
      onClick: ROUTES.PROJECT_LIST.path({ companyId, solutionId: s.id, solutionType: 'smart-uploader' }),
    }));
  }

  const getSolutionsMenuItems = () => {
    return solutions.map((s, idx) => {
      const overviewRouteMatch = compareRoute(ROUTES.CAMPAIGN_OVERVIEW.path({ companyId, solutionId: s.id }), pathname);
      const followupRouteMatch = (s.id === followup && menu === 'PLATFORM' && queryParams.includes('followup'))
        || matchRoute(new RegExp(`^${ROUTES.CREATE_PROJECT.path({ companyId: MATCH_ID_REGEX, solutionId: s.id, solutionType: 'platform' })}$`), pathname);
      const applicationsRouteMatch = followupRouteMatch && queryParams.includes('tab=APPLICATIONS');
      const ordersRouteMatch = followupRouteMatch && queryParams.includes('tab=ORDERS');
      const videosRouteMatch = (followupRouteMatch && queryParams.includes('tab=VIDEOS'))
        || matchRoute(new RegExp(`^${ROUTES.CREATE_PROJECT.path({ companyId: MATCH_ID_REGEX, solutionId: s.id, solutionType: 'platform' })}$`), pathname);
      const productsRouteMatch =
        matchRoute(new RegExp(`^${ROUTES.PRODUCT_LIST.path({ companyId: MATCH_ID_REGEX, solutionId: s.id })}$`), pathname) ||
        matchRoute(new RegExp(`^${ROUTES.UPDATE_PRODUCT.path({ companyId: MATCH_ID_REGEX, productId: MATCH_CRYPT_ID_REGEX, solutionId: s.id })}$`), pathname);

      const plateformMatch =
        solutionDropdownMenu === s.id || overviewRouteMatch || applicationsRouteMatch || ordersRouteMatch || videosRouteMatch || productsRouteMatch;

      return {
        title: s.name || t(`Campaign ${idx + 1}`),
        icon: 'ROCKET_LAUNCH_SOLID',
        open: plateformMatch,
        onClick: () => setSolutionDropdownMenu(solutionDropdownMenu === s.id ? null : s.id),
        children: getSubMenuItems(s, overviewRouteMatch, followupRouteMatch, applicationsRouteMatch, ordersRouteMatch, videosRouteMatch, productsRouteMatch),
      };
    });
  };

  const getExternalVideoItems = (routeMatch) => {
    if (externalVideoOption) {
      return [
        {
          name: 'External videos',
          onClick: ROUTES.EXTERNAL_VIDEO.path({ companyId }),
          selected: routeMatch,
        },
      ];
    }
    return [];
  };

  const getQrCodeMenuItems = (configurationRouteMatch, generationRouteMatch) => {
    if (!qrcodeOption) {
      return [];
    }

    return [
      {
        name: t('QR Code'),
        open: qrcodeDropdownMenu || configurationRouteMatch || generationRouteMatch,
        onClick: () => setQrcodeDropdownMenu(!qrcodeDropdownMenu),
        children: [
          {
            name: t('Configuration'),
            selected: configurationRouteMatch,
            onClick: ROUTES.QRCODE_TEMPLATES.path({ companyId }),
          },
          {
            name: t('Generation'),
            selected: generationRouteMatch,
            onClick: ROUTES.QRCODE_GENERATION.path({ companyId }),
          },
        ],
      },
    ];
  };

  const getAllVideosMenuItems = () => {
    const configurationRouteMatch = matchRoute(new RegExp(`^${ROUTES.QRCODE_TEMPLATES.path({ companyId: MATCH_ID_REGEX })}$`), pathname);
    const generationRouteMatch = matchRoute(new RegExp(`^${ROUTES.QRCODE_GENERATION.path({ companyId: MATCH_ID_REGEX })}$`), pathname);
    const broadcastingRouteMatch =
      matchRoute(new RegExp(`^${ROUTES.BROADCASTING.path({ companyId: MATCH_ID_REGEX })}$`), pathname) ||
      (matchRoute(
        new RegExp(`^${ROUTES.PROJECT.path({ companyId: MATCH_ID_REGEX, solutionType: '[a-zZ-Z]+', solutionId: MATCH_ID_REGEX, projectId: MATCH_ID_REGEX })}$`),
        pathname
      ) &&
        queryParams.includes('previous=BROADCASTING'));
    const externalVideosRouteMatch = matchRoute(new RegExp(`^${ROUTES.EXTERNAL_VIDEO.path({ companyId: MATCH_ID_REGEX })}$`), pathname);
    const galleryRouteMatch =
      (matchRoute(
        new RegExp(`^${ROUTES.PROJECT.path({ companyId: MATCH_ID_REGEX, solutionType: '[a-zZ-Z]+', solutionId: MATCH_ID_REGEX, projectId: MATCH_ID_REGEX })}$`),
        pathname
      ));

    return [
      {
        title: t('All my videos'),
        icon: 'VIDEO_SOLID',
        open:
          allVideosDropdownMenu || configurationRouteMatch || generationRouteMatch || broadcastingRouteMatch || externalVideosRouteMatch || galleryRouteMatch,
        onClick: () => setAllVideosDropdownMenu(!allVideosDropdownMenu),
        children: [
          {
            key: 'VIDEOS-BROADCASTING',
            name: t('Broadcasting'),
            onClick: ROUTES.BROADCASTING.path({ companyId }),
            selected: broadcastingRouteMatch,
          },
          ...getExternalVideoItems(externalVideosRouteMatch),
          ...getQrCodeMenuItems(configurationRouteMatch, generationRouteMatch),
        ].filter(Boolean),
      },
    ];
  };

  const getDataMenuItem = () => {
    if (!dataOption) {
      return [];
    }
    return [
      {
        title: t('Data'),
        icon: 'ANALYTICS',
        isSelected:
          matchRoute(new RegExp(`^${ROUTES.DASHBOARD_DATA.path({ companyId: MATCH_ID_REGEX })}$`), pathname) ||
          matchRoute(new RegExp(`^${ROUTES.VIDEO_DATA.path({ companyId: MATCH_ID_REGEX })}$`), pathname) ||
          matchRoute(new RegExp(`^${ROUTES.QRCODE_DATA.path({ companyId: MATCH_ID_REGEX })}$`), pathname) ||
          matchRoute(new RegExp(`^${ROUTES.PRODUCT_DATA.path({ companyId: MATCH_ID_REGEX })}$`), pathname),
        onClick: ROUTES.DASHBOARD_DATA.path({ companyId }),
      },
    ];
  };

  const getShoppableContentMenuItem = () => {
    return [
      {
        title: t('Shoppable Content'),
        icon: 'VSG',
        isSelected:
          matchRoute(new RegExp(`^${ROUTES.SHOPPABLE_CONTENT_WIDGET.path({ companyId: MATCH_ID_REGEX })}$`), pathname),
        onClick: ROUTES.SHOPPABLE_CONTENT_WIDGET.path({ companyId }),
      },
    ];
  };

  const getDeveloperMenuItems = () => ({
    title: t('Technical documentation'),
    icon: 'FILE_ALT',
    onClick: ROUTES.DEVELOPERS.path({ companyId }),
  });

  const config = [getGeneralMenuItem(), ...getSolutionsMenuItems(), ...getSmartUploaderItems(), ...getAllVideosMenuItems(), ...getDataMenuItem(), ...getShoppableContentMenuItem(), getDeveloperMenuItems()].map((c) => ({
    isSelected: c.onClick === pathname,
    ...c,
  }));

  return <ClientMenu open={open} config={config} isLoading={getSolutionsLoading} />;
};

export default ClientMenuContainer;
