import { union } from 'lodash';
import Papa from 'papaparse';
import { useState } from 'react';
import { read, utils } from 'xlsx';

export const useExtractFromCsv = (headersOrdered, includeFirstRow = false) => {
  const [loading, setFileLoading] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [file, setFile] = useState(null);

  const loadFile = async (file) => {
    setFile(null);
    setData(null);
    setHeaders(null);
    setError(null);

    if (file) {
      setFileLoading(true);
      setFile(file);

      try {
        let rawData;
        if (file.type === 'text/csv') {
          const arrayBuffer = await file.arrayBuffer();

          const decoder = new TextDecoder('ISO-8859-1');
          const fileContent = decoder.decode(arrayBuffer);

          const parseResult = Papa.parse(fileContent, {
            header: true,
            skipEmptyLines: true,
          });
          rawData = parseResult.data;
        } else {
          const workbook = read(await file.arrayBuffer().catch(setError), {
            type: 'buffer',
          });
          const ws = workbook.Sheets[workbook.SheetNames[0]];
          rawData = utils.sheet_to_json(ws, {
            raw: false,
            defval: '',
            header: includeFirstRow ? 1 : undefined,
          });
        }

        if (rawData.length === 0) {
          throw new Error('File contains no data after parsing');
        }

        let processedData = rawData.map(row => Object.values(row));

        const trimData = processedData.map((d) =>
          d.map(cell => (cell || '').toString().trim())
        ).filter(row => row.some(cell => cell !== ''));

        const emptyIndexes = [];
        trimData[0]?.forEach((_, index) => {
          if (trimData.every((elem) => !elem[index])) {
            emptyIndexes.unshift(index);
          }
        });

        trimData.forEach((elem) => {
          emptyIndexes.forEach((i) => elem.splice(i, 1));
        });

        if (trimData.length === 0) {
          throw new Error('No valid data found in the file after processing');
        }

        setData(trimData);

        // Get only keys with values, sort it in terms of the headersOrdered and map it for the table
        const headersValues = union(
          ...rawData.map((d) =>
            Object.keys(d)
              .map((key) =>
                d[key] !== ''
                  ? key
                    .replace('*', '')
                    .replace('split multiple value with ;', '') // remove product second line
                    .replace('(if multiple, split on ";")', '') // remove external video second line
                    .replace(/[\r\n]+/, '')
                    .trim()
                  : null
            )
              .filter((k) => !!k)
          )
        )
          .sort((a, b) => headersOrdered.indexOf(a) - headersOrdered.indexOf(b))
          .map((h, i) => ({ name: h.trim().toLowerCase(), position: i }));

        setHeaders(headersValues);
        setFileLoading(false);

        return {
          data: trimData,
          headers: headersValues,
        };
      } catch (err) {
        console.error('Error processing file:', err);
        setError(err.message || 'Error processing file');
        setFileLoading(false);
        return { data: null, headers: null };
      }
    }
  };

  return [{ loading, error, data, file, headers }, loadFile];
};